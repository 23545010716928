import { prefetchQueries } from '@tools/query'
import { useModals } from '@uikit/organisms/modals'
import type { GetServerSideProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useEffect } from 'react'
import HomePage from '..'

const LoginPage = () => {
  const { setModal } = useModals()

  useEffect(() => {
    setModal({ name: 'login', props: {} })
  }, [])

  return <HomePage />
}

export default LoginPage

export const getServerSideProps: GetServerSideProps = async ({
  locale,
  ...options
}) => {
  const dehydratedState = await prefetchQueries(options)

  return {
    props: {
      ...(await serverSideTranslations(locale!, ['common'])),
      ...dehydratedState,
    },
  }
}
